import React from "react";
import { Container, Row } from "react-bootstrap";
import '../../styles.css'

function Sobre() {
  return (
    <>
    <div class="imgBack" style={{marginBottom: '-100px'}}>
    <div class="dadosProd2">
            <div>
                <h4 class="ccIcon" style={{marginTop: '5px', marginBottom: '2px'}}>QUEM SOMOS</h4>
            </div>
        </div>
    <Container className="my-5">
      <Row>
        <div class="fundoSobre" style={{marginTop: '-30px'}}>
          <h2 class="titulo">A Tecmetrol</h2>
          <ul>
            <li>
              Atuando no mercado desde 1986, a Tecmetrol foi fundada visando
              atender às necessidades de clientes do Mercado Brasileiro que
              necessitam de Soluções para Medições e Controle, por Dispositivos
              de Controle e Sistemas de Medição Especiais.
            </li>
            <li>
              Desde nossa fundação, buscamos conhecer as demandas de nossos
              Clientes, através do Conhecimento dos seus Processos para, desta
              maneira, poder Apresentar as Melhores Soluções, tratando cada
              Cliente e Projeto de Maneira Customizada.
            </li>
            <li>
              Desde 2002 atuamos também no Mercado Externo, fornecendo Soluções
              Especiais a Grandes Clientes na Europa, Ásia e América do Norte,
              atendendo seus Rigorosos Requisitos de Qualidade.
            </li>
            <li>
              Estamos atentos às Demandas dos Clientes e do Mercado. Nesse
              sentido, buscamos a constante atualização de nossos Produtos e
              Soluções, como a Compatibilidade de nossas Soluções com Projetos
              voltados à Indústria 4.0. Apresentamos as Informações com
              Qualidade e Confiabilidade, para que essas sejam utilizadas nas
              Tomadas de Decisão por nossos Clientes.
            </li>
            <li>
              Essa é a Tecmetrol, uma Empresa Pronta para Apresentar as Melhores
              Soluções para Sistemas de Controle e Medição, Fidelizando as
              Relações Comerciais Através da Busca Constante do Melhor Custo x
              Benefício aos nossos Clientes.
            </li>
          </ul>
          </div>
      </Row>
      <br></br>
      <Row>
      <div class="fundoSobre">
          <h2>Time Tecmetrol</h2>
          <h3>Estrututa interna completa:</h3>
          <ul>
            <li>Projetos Mecânicos, Elétricos, Eletrônicos e Software</li>
            <li>Processos de Fabricação Completos</li>
            <li>
              Medição e Calibração (Certificado pela RBC – Rede Brasileira de
              Calibração)
            </li>
            <li>Montagem Elétrica e Eletrônica</li>
            <li>Desenvolvimento e Implementação de Software</li>
            <li>Assistência Técnica</li>
          </ul>
          <h3>Processos Terceirizados (Fornecedores Certificados):</h3>
          <ul>
            <li>Tratamento Superficial</li>
            <li>Tratamento Térmico</li>
          </ul>
          </div>
          </Row>
          <br></br>
          <Row>
          <div class="fundoSobre">
          <h2>Nossos Produtos</h2>
          <ul>
            <li>Dispositivos de Medição e Controle em Geral</li>
            <li>Dispositivos Pneumáticos e Eletro-Pneumáticos</li>
            <li>Dispositivos Mecânicos</li>
            <li>
              Padrões de Calibração (Certificados pela RBC – Rede Brasileira de
              Calibração)
            </li>
            <li>Bancadas de Medição</li>
            <li>Hardware e Software</li>
          </ul>
          </div>
        </Row>
        <br></br>
        <Row>
          <div class="fundoSobre">
              <h2>Clientes</h2>
              <div class="clientes">
              <ul>
                <h4 class="listaTitle">BRASIL</h4>
                <li class="li2">Robert Bosch Ltda.</li>
                <li class="li2">Renault do Brasil S/A</li>
                <li class="li2">Nidec Global Appliance Brasil Ltda.</li>
                <li class="li2">Weg Motores</li>
                <li class="li2">Weg Linhares Eq. El. S/A</li>
                <li class="li2">Volkswagen do Brasil S/A</li>
                <li class="li2">ZF Sistemas de Direção Ltda.</li>
                <li class="li2">DHB Comp. Automotivos Ltda.</li>
                <li class="li2">Fundição TUPY Ltda.</li>
                <li class="li2">Stellantis</li>
                <li class="li2">Bosch Rexroth Ltda.</li>
              </ul>
              <ul>
              <h4 class="listaTitle">MUNDO</h4>
                <li class="li2">Bosch Automotive Diesel Systems Co. - CHINA</li>
                <li class="li2">Robert Bosch GMBH - Stuttgart - ALEMANHA</li>
                <li class="li2">Satherm GMBH - ALEMANHA</li>
                <li class="li2">
                Motor – Industries Co. - ÍNDIA
                </li>
                <li class="li2">Nidec Compressor Co. Ltd. - ESLOVÁQUIA</li>
                <li class="li2">Nidec Global Appliance Co. Ltd. - MÉXICO</li>
                <li class="li2">Nidec Compressor Co. Ltd. - CHINA</li>
                <li class="li2">Euraltech Controles - FRANÇA</li>
                <li class="li2">Mecalix Controle e Messure - FRANÇA</li>
                <li class="li2">Axorys Systems Les Ulis - FRANÇA</li>
                <li class="li2">Clemessy S/A - FRANÇA</li>
                <li class="li2">RC Meca Realisations Contr. Mec. - FRANÇA</li>
                <li class="li2">Promaktiv - RÚSSIA</li>
              </ul>
              </div>
            </div>
      </Row>
    </Container>
    </div>
    </>
  );
}

export default Sobre;