import React from 'react'
import "../../styles.css"

import produto1 from "../../images/produtos/produto1.png"
import produto2 from "../../images/produtos/produto2.png"
import produto3 from "../../images/produtos/produto3.png"
import produto4 from "../../images/produtos/produto4.png"
import produto5 from "../../images/produtos/produto5.png"
import produto6 from "../../images/produtos/produto6.png"
import produto7 from "../../images/produtos/produto7.png"
import produto8 from "../../images/produtos/produto8.png"
import produto9 from "../../images/produtos/produto9.png"
import produto10 from "../../images/produtos/produto10.png"
import produto11 from "../../images/produtos/produto11.png"
import maquina1 from "../../images/produtos/maquina1.png"
import maquina2 from "../../images/produtos/maquina2.png"
import maquina3 from "../../images/produtos/maquina3.png"
import maquina4 from "../../images/produtos/maquina4.png"
import maquina5 from "../../images/produtos/maquina5.png"
import maquina6 from "../../images/produtos/maquina6.png"
import maquina7 from "../../images/produtos/maquina7.png"
import maquina8 from "../../images/produtos/maquina8.png"
import maquina9 from "../../images/produtos/maquina9.png"
import maquina10 from "../../images/produtos/maquina10.png"
import maquina11 from "../../images/produtos/maquina11.png"
import maquina12 from "../../images/produtos/maquina12.png"
import maquina13 from "../../images/produtos/maquina13.png"
import maquina14 from "../../images/produtos/maquina14.png"
import maquina15 from "../../images/produtos/maquina15.png"
import maquina16 from "../../images/produtos/maquina16.png"

export default function Produtos() {
   

    return (
        <>
        <div class="imgBack" style={{marginBottom: '-100px'}}>
        <div class="dadosProd2">
            <div>
                <h4 class="ccIcon" style={{marginTop: '5px', marginBottom: '2px'}}>NOSSOS PRODUTOS</h4>
            </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Dispositivos de Medição e padrões de Calibração</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto1} alt="" class="iconesProd2"/>
                    </div>
        </div>
        <div class="dadosProd">
        <div className="fundoTitulo">
                    <h4 class="ccIcon" >Colunas Eletro-Pneumáticas com 01 Canal de Medição - 01 Dispositivo</h4>
        </div>
            <div class="iconeDiv2">
                <img src={produto2} alt="" class="iconesProd"/>
                    </div>
                    <div>
                        <h4 class="cc2">Resolução: 0,0002 mm</h4>
                        <h4 class="cc2">Alcance: ± 0,006 até ± 0,060 mm</h4>
                    </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Módulos Micro-Processados com 02, 03 ou 04 Canais de Medição – até 04 Dispositivos Simultâneos</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto3} alt="" class="iconesProd"/>
                    </div>
                    <div style={{marginTop: "-30px"}}>
                        <h4 class="cc2">Resolução: 0,0001 mm</h4>
                        <h4 class="cc2">Alcance: ± 0,002  até ± 0,150 mm</h4>
                    </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Módulos Micro-Processados Tecmetrol - Última geração</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto4} alt="" class="iconesProd2"/>
                    </div>
                    <div>
                    <h4 class="ccIcon">MODELOS</h4>
                    <h4 class="cc2">MICRO – PC W10 com 2 Canais</h4>
                    <h4 class="cc2">MICRO – PC W10 com 3 Canais</h4>
                    <h4 class="cc2">MICRO – PC W10 com 4 Canais</h4>
                    <br></br>
                    <div>
                        <h4 class="cc2">Resolução: 0,0001 mm</h4>
                        <h4 class="cc2">Alcance: ± 0,002 até ± 0,150 mm</h4>
                    </div>
                    <br></br>
                        <h4 class="cc2" style={{width: "70%"}}>O Equipamento trabalha através da Platforma Windows, com a Aplicação do Software LabView. Há opções com 02, 03 ou 04, até 08 Canais de Medição</h4>
                        <br></br>
                        <h4 class="cc2" style={{width: "70%"}}>Este Equipamento trabalha com o Sistema AUTO-ZERO, que é uma Rotina de Software, a um padrão de referência e faz a compensação automática do Sistema aos Valores de Referência previamente definidos</h4>
                    </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Medição Eletro-Pneumática para controle do Paralelismo e Perpendicularidade</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto5} style={{marginLeft: '20px'}} alt="" class="iconesProd"/>
                    </div>
                    <div>
                    <h4 class="ccIcon">Módulos Micro-Processados com 02, 03 ou 04 Canais de Medição - até 04 Dispositivos Simultâneos</h4>
                        <h4 class="cc2">(Cliente: EMBRACO CHINA / MÉXICO)</h4>
                    </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Cabine especial com múltiplas medições - Sistema Micro-Processado</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto6} alt="" class="iconesProd"/>
                    </div>
                <div>
                    <h4 class="ccIcon">Estações de Medição para Controle de  Componentes do Compressor</h4>
                    <h4 class="cc2">(Cliente: EMBRACO BRASIL, CHINA, ESLOVÁQUIA)</h4>
                </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Solução completa de Controle de Componentes do compressor - Linhas de Montagem e Usinagem</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto7} alt="" class="iconesProd"/>
                    </div>
                <div>
                    <h4 class="cc2">(Cliente: EMBRACO BRASIL, CHINA, ESLOVÁQUIA)</h4>
                </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Bancada completa de Controle de folga de componentes</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto8} alt="" class="iconesProd"/>
                    </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Bancada de Medição - Controle de dimensões e formas</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto9} alt="" class="iconesProd"/>
                    </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Equipamento especial e sistemas para múltiplas medições</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto10} alt="" class="iconesProd"/>
                    </div>
                <div>
                    <h4 class="ccIcon">Bancada de Medição da Vela de Ignição</h4>
                    <h4 class="cc2">(Cliente: BOSCH)</h4>
                </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Garfos de medição e módulos Eletro-Pneumáticos para medição de eixo em equipamento automático</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto11} alt="" class="iconesProd"/>
                    </div>
                <div>
                    <h4 class="cc2">(Clientes: FRANÇA (MECALIX / EURALTHEC / CLEMESSY...))</h4>
                </div>
        </div>
        <br></br>
        <div class="dadosProd2" style={{borderTopLeftRadius: '40px', borderTopRightRadius: '40px'}}>
            <div>
                <h4 class="ccIcon" style={{marginTop: '5px', marginBottom: '2px'}}>NOSSAS MÁQUINAS</h4>
            </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Fresadora Ferramenteira</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina1} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Fresadora Ferramenteira</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina2} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Afiadora Ferramenteira</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina3} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Metroscópio Universal Horizontal - Carl Zeiss Jena - ULM 600 / Série 0135</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina4} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Torno Ferramenteiro</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina5} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Torno Mecânico</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina6} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Torno Revólver de fricção</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina7} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Retífica Studer de precisão</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina8} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Retífica Cilíndrica</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina9} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Retífica Plana</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina10} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Lixadeira</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina11} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Serra de fita</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina12} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Coordenada Tridmensional</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina13} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Acessórios para fresa e medição</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina14} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Máquina para jateamento</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina15} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Placas Divisoras</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina16} alt="" class="iconesProd"/>
                </div>
            </div>
        </div>
        </>
    )
}