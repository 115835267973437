import CustomNavbar from './Components/CustomNavbar/CustomNavbar';
import CustomNavbarEN from './Components/CustomNavbarEN/CustomNavbarEN';
import CustomCarouselEN from './Components/CustomCarouselEN/CustomCarouselEN';
import CustomNavbarES from './Components/CustomNavbarES/CustomNavbarES';
import CustomCarouselES from './Components/CustomCarouselES/CustomCarouselES';
import CustomCarousel from './Components/CustomCarousel/CustomCarousel';

import {Routes, Route} from 'react-router-dom'
import Produtos from './pages/Produtos/produtos';
import Sobre from './pages/Sobre/sobre';
import Contato from './pages/Contato/contato';
import ProdutosEN from './pagesEN/Product/product';
import SobreEN from './pagesEN/About/about';
import ContatoEN from './pagesEN/Contact/contact';
import ProdutosES from './pagesES/Producto/producto';
import SobreES from './pagesES/Acerca/acerca';
import ContatoES from './pagesES/Contacto/contacto';

import React, { useState, useEffect } from 'react';


function App() {

  const [isEnglish, setIsEnglish] = useState(false);
  const [isSpanish, setIsSpanish] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    setIsEnglish(currentPath.includes('/en'));
    setIsSpanish(currentPath.includes('/es'));
  }, []);

  return (
    <>
     {isEnglish ? (
  <>
    <CustomNavbarEN />
    <Routes>
      <Route path='/en' element={<CustomCarouselEN />} />
      <Route path='/en-product' element={<ProdutosEN />} />
      <Route path='/en-about' element={<SobreEN />} />
      <Route path='/en-contact' element={<ContatoEN />} />
    </Routes>
  </>
) : (isSpanish ? (
  <>
    <CustomNavbarES />
    <Routes>
      <Route path='/es' element={<CustomCarouselES />} />
      <Route path='/es-producto' element={<ProdutosES />} />
      <Route path='/es-acerca' element={<SobreES />} />
      <Route path='/es-contacto' element={<ContatoES />} />
    </Routes>
  </>
) : (
  <>
    <CustomNavbar />
    <Routes>
      <Route path='/' element={<CustomCarousel />} />
      <Route path='/produtos' element={<Produtos />} />
      <Route path='/sobre' element={<Sobre />} />
      <Route path='/contato' element={<Contato />} />
    </Routes>
  </>
))}
    </>
     
  );
}

export default App;
