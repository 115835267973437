import React from "react";
import { Container, Row } from "react-bootstrap";
import '../../styles.css'

function About() {
  return (
    <>
    <div class="imgBack" style={{marginBottom: '-100px'}}>
    <div class="dadosProd2">
            <div>
                <h4 class="ccIcon" style={{marginTop: '5px', marginBottom: '2px'}}>QUIENES SOMOS</h4>
            </div>
        </div>
    <Container className="my-5">
      <Row>
        <div class="fundoSobre" style={{marginTop: '-30px'}}>
          <h2 class="titulo">La Tecmetrol</h2>
          <ul>
            <li>
            Actuando en el mercado desde 1986, Tecmetrol fue fundada para Atender las necesidades
            de Clientes en el Mercado Brasileño que necesitan Soluciones de Medición y
            Control, a través de Dispositivos de Control y Sistemas Especiales de Medición.
            </li>
            <li>
            Desde nuestra fundación, buscamos conocer las demandas de nuestros Clientes, a través de la
            Conocimiento de sus Procesos, para tener la Capacidad de Presentar lo mejor
            Soluciones, tratando cada Cliente y Proyecto de Manera Personalizada.
            </li>
            <li>
            Desde 2002 también operamos en el Mercado Exterior, brindando Especial
            Soluciones a Grandes Clientes en Europa, Asia y Norteamérica, satisfaciendo sus
            estrictos requisitos de calidad.
            </li>
            <li>
            Estamos atentos a las Demandas del Cliente y del Mercado. En este sentido, buscamos constantemente
             actualizar nuestros Productos y Soluciones, como la Compatibilidad de nuestras Soluciones con
             Proyectos orientados a la Industria 4.0. Presentamos la Información con Calidad y Confiabilidad, para que pueda ser utilizada en la Toma de Decisiones por parte de nuestros Clientes.
            </li>
            <li>
            Así es Tecmetrol, una Empresa dispuesta a presentar las mejores soluciones para sistemas de control y medición,
            fidelización en las relaciones comerciales a través de la búsqueda constante del mejor costo x beneficio
            a nuestros clientes.
            </li>
          </ul>
          </div>
      </Row>
      <br></br>
      <Row>
      <div class="fundoSobre">
          <h2>Equipo Tecmetrol</h2>
          <h3>Estructura interna completa:</h3>
          <ul>
            <li>Proyectos Mecánicos, Eléctricos, Electrónicos y Software</li>
            <li>Procesos completos de fabricación</li>
            <li>
            Medición y Calibración (Certificado por RBC – Red Brasileña de Calibración)
            </li>
            <li>Montaje Eléctrico y Electrónico</li>
            <li>Desarrollo e Implementación de Software</li>
            <li>Asistencia técnica</li>
          </ul>
          <h3>Procesos Tercerizados (Proveedores Certificados):</h3>
          <ul>
            <li>Tratamiento superficial</li>
            <li>Tratamiento térmico</li>
          </ul>
          </div>
          </Row>
          <br></br>
          <Row>
          <div class="fundoSobre">
          <h2>Nuestros productos</h2>
          <ul>
            <li>Dispositivos de Medida y Control en General</li>
            <li>Dispositivos neumáticos y electroneumáticos</li>
            <li>Dispositivos mecánicos</li>
            <li>
            Estándares de Calibración (Certificados por RBC - Red Brasileña de Calibración)
            </li>
            <li>Bancos de medida</li>
            <li>Hardware y software</li>
          </ul>
          </div>
          </Row>
          <br></br>
          <Row>
          <div class="fundoSobre">
            <h2>Clientes</h2>
            <div class="clientes">
            <ul>
              <h4 class="listaTitle">BRASIL</h4>
              <li class="li2">Robert Bosch Ltda.</li>
              <li class="li2">Renault do Brasil S/A</li>
              <li class="li2">Nidec Global Appliance Brasil Ltda.</li>
              <li class="li2">Weg Motores</li>
              <li class="li2">Weg Linhares Eq. El. S/A</li>
              <li class="li2">Volkswagen do Brasil S/A</li>
              <li class="li2">ZF Sistemas de Direção Ltda.</li>
              <li class="li2">DHB Comp. Automotivos Ltda.</li>
              <li class="li2">Fundição TUPY Ltda.</li>
              <li class="li2">Stellantis</li>
              <li class="li2">Bosch Rexroth Ltda.</li>
            </ul>
            <ul>
            <h4 class="listaTitle">MUNDO</h4>
              <li class="li2">Bosch Automotive Diesel Systems Co. - CHINA</li>
              <li class="li2">Robert Bosch GMBH - Stuttgart - GERMANY</li>
              <li class="li2">Satherm GMBH - GERMANY</li>
              <li class="li2">
              Motor – Industries Co. - INDIA
              </li>
              <li class="li2">Nidec Compressor Co. Ltd. - ESLOVAQUIA</li>
              <li class="li2">Nidec Global Appliance Co. Ltd. - MÉXICO</li>
              <li class="li2">Nidec Compressor Co. Ltd. - CHINA</li>
              <li class="li2">Euraltech Controles - FRANCIA</li>
              <li class="li2">Mecalix Controle e Messure - FRANCIA</li>
              <li class="li2">Axorys Systems Les Ulis - FRANCIA</li>
              <li class="li2">Clemessy S/A</li>
              <li class="li2">RC Meca Realisations Contr. Mec. - FRANCIA</li>
              <li class="li2">Promaktiv - RUSIA</li>
            </ul>
            </div>
          </div>
      </Row>
    </Container>
    </div>
    </>
  );
}

export default About;