import React from 'react';
import { Carousel } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import img1 from '../../images/C1.png'
import img2 from '../../images/C2.png'
import img3 from "../../images/C3.png";
import mapa from "../../images/mapa.png";
import logotec2 from "../../images/logo/logobody.png";
import './CustomCarousel.css';
import '../../styles.css'

export default function CustomCarousel() {
    const isSmallScreen = useMediaQuery({ maxWidth: 768 });

    return (
      <>
        {isSmallScreen ? (
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={img3} alt="Third slide" />
              <Carousel.Caption id='caption'>
                <img className="logotec3" src={logotec2} alt=""/>
                <p>
                  Uma empresa pronta para apresentar as melhores soluções para sistemas de controle e medição, fidelizando as relações comerciais através da busca constante do melhor CUSTO x BENEFÍCIO aos nossos clientes.
                </p>
                <img class="mapa" src={mapa} alt=""/>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img1} alt="First slide" />
              <Carousel.Caption id='caption'>
                <h3 class="c">Atuando no Mercado desde 1986</h3>
                <p style={{marginTop: "-70px"}} class="c2">
                  A Tecmetrol foi fundada visando atender às necessidades de clientes do Mercado Brasileiro que necessitam de soluções para medições e controle, por dispositivos de controle e sistemas de medição especiais.
                </p>
                <br></br>
                <p style={{marginTop: "-20px"}}class="c2">Equipamentos de alta precisão e Sistemas de medição automatizados, Hardwares e Periféricos, Dispositivos de medição e Softwares de medição.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img2} alt="Second slide" />
              <Carousel.Caption id='caption'>
                <h3 class="c">Desde 2002 atuamos também no mercado externo</h3>
                <p class="c2" style={{marginTop: '70px'}}>Fornecendo soluções especiais a grandes Clientes na Europa, Ásia e América do Norte, atendendo seus rigorosos requisitos de qualidade.</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        ) : (
            <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={img3} alt="Third slide" />
              <Carousel.Caption id='caption'>
                <img className="logotec2" src={logotec2} alt=""/>
                <p>
                  Uma empresa pronta para apresentar as melhores soluções para sistemas de controle e medição, fidelizando as relações comerciais através da busca constante do melhor CUSTO x BENEFÍCIO aos nossos clientes.
                </p>
                <img class="mapa2" src={mapa} alt=""/>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img1} alt="First slide" />
              <Carousel.Caption id='caption'>
                <h3 class="c">Atuando no mercado desde 1986</h3>
                <p class="c2" style={{marginTop: '40px'}}>
                  A Tecmetrol foi fundada visando atender às necessidades de clientes do Mercado Brasileiro que necessitam de soluções para medições e controle, por dispositivos de controle e sistemas de medição especiais.
                </p>
                <br></br>
                <p class="c2">Equipamentos de alta precisão e Sistemas de medição automatizados, Hardwares e Periféricos, Dispositivos de medição e Softwares de medição.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img2} alt="Second slide" />
              <Carousel.Caption id='caption'>
                <h3 class="c">Desde 2002 atuamos também no mercado externo</h3>
                <p class="c2" style={{marginTop: '100px'}}>Fornecendo soluções especiais a grandes <br />clientes na Europa, Ásia e América do Norte, <br /> atendendo seus rigorosos requisitos de qualidade.</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        )}
      </>
    );
}
