import React, { useState } from "react";
import { Navbar, Nav, Container} from "react-bootstrap";
import '../../styles.css'
import log from "../../images/logo/logo9.png"
import br from "../../images/br.png"
import us from "../../images/usa.png"
import esp from "../../images/esp.png"

function CustomNavbarES() {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  
  return (
      <Navbar bg="dark" variant="dark" expand="lg" expanded={expanded}>
      <Container>
        <Navbar.Brand href="/es"><img src={log} alt="" class="logohead"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          <div style={{ display: 'flex' }}>
          <Nav.Link href="/" style={{width: '60px', margin: '0'}}>
            <img src={br} className="pt" alt=""/>
            </Nav.Link>
            <Nav.Link href="/en" style={{width: '60px', margin: '0'}}>
            <img src={us} className="en" alt=""/>
          </Nav.Link>
          <Nav.Link href="/es" style={{width: '60px', margin: '0'}}>
            <img src={esp} className="es" alt=""/>
          </Nav.Link>
          </div>
          <Nav.Link href="/es-producto" className="links">Productos</Nav.Link>
            <Nav.Link href="/es-acerca" className="links">Acerca de nosotros</Nav.Link>
            <Nav.Link href="/es-contacto" className="links">Contacto</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavbarES;
