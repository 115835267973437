import React from 'react';
import { Carousel } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import img1 from '../../images/C1.png'
import img2 from '../../images/C2.png'
import img3 from "../../images/C3.png";
import mapa from "../../images/mapaEN.png";
import logotec2 from "../../images/logo/logobodyEN.png";
import '../CustomCarousel/CustomCarousel.css';
import '../../styles.css'

export default function CustomCarouselEN() {
    const isSmallScreen = useMediaQuery({ maxWidth: 768 });

    return (
      <>
        {isSmallScreen ? (
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={img3} alt="Third slide" />
              <Carousel.Caption id='caption'>
              <div>
                <img className="logotec3" src={logotec2} alt=""/>
                <p>
                A Company ready to present the best solutions for control and measurement systems, building loyalty in commercial relationships through the constant search for the best COST x BENEFIT to our customers.
                </p>
                <img class="mapa" src={mapa} alt=""/>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img1} alt="First slide" />
              <Carousel.Caption id='caption'>
                <h3 class="c">Operating in the market since 1986</h3>
                <p class="c2" style={{marginTop: "-50px"}}>
                Tecmetrol was founded to Attend the needs of Customers in the Brazilian Market who need Solutions for Measurement and Control, through Control Devices and Special Measurement Systems.
                </p>
                <br></br>
                <p style={{marginTop: "-20px"}}class="c2">High precision equipments and automated measuring systems, Hardwares and Peripherals, Measuring Devices and Measuring Softwares.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img2} alt="Second slide" />
              <Carousel.Caption id='caption'>
                <h3 class="c">Since 2002 we have <br/>also been operating in the Foreign Market</h3>
                <p class="c2" style={{marginTop: '70px'}}>Providing special solutions to large <br/> customers in Europe, Asia and North America, <br/> meeting their stringent Quality Requirements.</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        ) : (
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={img3} alt="Third slide" />
              <Carousel.Caption id='caption'>
                <img className="logotec2" src={logotec2} alt=""/>
                <p>
                A Company ready to present the best solutions for control and measurement systems, building loyalty in commercial relationships through the constant search for the best COST x BENEFIT to our customers.
                </p>
                <img class="mapa2" src={mapa} alt=""/>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img1} alt="First slide" />
              <Carousel.Caption id='caption'>
                <h3 class="c">Operating in the market since 1986</h3>
                <p class="c2" style={{marginTop: '40px'}}>
                Tecmetrol was founded to Attend the needs of Customers in the Brazilian Market who need Solutions for Measurement and Control, through Control Devices and Special Measurement Systems.
                </p>
                <br></br>
                <p class="c2">High precision equipments and automated measuring systems, Hardwares and Peripherals, Measuring Devices and Measuring Softwares.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img2} alt="Second slide" />
              <Carousel.Caption id='caption'>
                <h3 class="c">Since 2002 we have also been operating in the Foreign Market</h3>
                <p class="c2"  style={{marginTop: '100px'}}>Providing Special Solutions to Large <br/> Customers in Europe, Asia and North America, <br/> meeting their stringent Quality Requirements.</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        )}
      </>
    );
}
