import React from 'react'
import "../../styles.css"

import produto1 from "../../images/produtos/produto1.png"
import produto2 from "../../images/produtos/produto2.png"
import produto3 from "../../images/produtos/produto3.png"
import produto4 from "../../images/produtos/produto4.png"
import produto5 from "../../images/produtos/produto5EN.png"
import produto6 from "../../images/produtos/produto6.png"
import produto7 from "../../images/produtos/produto7.png"
import produto8 from "../../images/produtos/produto8.png"
import produto9 from "../../images/produtos/produto9.png"
import produto10 from "../../images/produtos/produto10.png"
import produto11 from "../../images/produtos/produto11.png"
import maquina1 from "../../images/produtos/maquina1.png"
import maquina2 from "../../images/produtos/maquina2.png"
import maquina3 from "../../images/produtos/maquina3.png"
import maquina4 from "../../images/produtos/maquina4.png"
import maquina5 from "../../images/produtos/maquina5.png"
import maquina6 from "../../images/produtos/maquina6.png"
import maquina7 from "../../images/produtos/maquina7.png"
import maquina8 from "../../images/produtos/maquina8.png"
import maquina9 from "../../images/produtos/maquina9.png"
import maquina10 from "../../images/produtos/maquina10.png"
import maquina11 from "../../images/produtos/maquina11.png"
import maquina12 from "../../images/produtos/maquina12.png"
import maquina13 from "../../images/produtos/maquina13.png"
import maquina14 from "../../images/produtos/maquina14.png"
import maquina15 from "../../images/produtos/maquina15.png"
import maquina16 from "../../images/produtos/maquina16.png"

export default function Products() {
   

    return (
        <>
        <div class="imgBack" style={{marginBottom: '-100px'}}>
        <div class="dadosProd2">
            <div>
                <h4 class="ccIcon" style={{marginTop: '5px', marginBottom: '2px'}}>NUESTROS PRODUCTOS</h4>
            </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Dispositivos de medición y patrones de calibración</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto1} alt="" class="iconesProd2"/>
                    </div>
        </div>
        <div class="dadosProd">
        <div className="fundoTitulo">
                    <h4 class="ccIcon" >Columnas electro-neumáticas con un canal de medición: un dispositivo</h4>
        </div>
            <div class="iconeDiv2">
                <img src={produto2} alt="" class="iconesProd"/>
                    </div>
                    <div>
                        <h4 class="cc2">Resolución: 0,0002 mm</h4>
                        <h4 class="cc2">Alcance: ± 0,006 hasta ± 0,060 mm</h4>
                    </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Módulos Microprocesados ​​con Dos, Tres o Cuatro Canales de Medida - hasta Cuatro Dispositivos Simultáneos</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto3} alt="" class="iconesProd"/>
                    </div>
                    <div style={{marginTop: "-30px"}}>
                        <h4 class="cc2">Resolución: 0,0001 mm</h4>
                        <h4 class="cc2">Alcance: ± 0,002 hasta ± 0,150 mm</h4>
                    </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Módulos de Tecmetrol Microprocesados ​​- Última generación</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto4} alt="" class="iconesProd2"/>
                    </div>
                    <div>
                    <h4 class="ccIcon">MODELOS</h4>
                    <h4 class="cc2">MICRO – PC W10 con 2 canales</h4>
                    <h4 class="cc2">MICRO – PC W10 con 3 canales</h4>
                    <h4 class="cc2">MICRO – PC W10 con 4 canales</h4>
                    <br></br>
                    <div>
                        <h4 class="cc2">Resolución: 0,0001 mm</h4>
                        <h4 class="cc2">Alcance: ± 0,002 hasta ± 0,150 mm</h4>
                    </div>
                    <br></br>
                        <h4 class="cc2" style={{width: "70%"}}>El equipo trabaja sobre Plataforma Windows, ejecutando un software de aplicación en LabView. Hay opciones con dos, tres, cuatro y hasta ocho canales de medida</h4>
                        <br></br>
                        <h4 class="cc2" style={{width: "70%"}}>Este equipo opera con el Sistema AUTO-ZERO, que es una rutina de software que realiza ajustes de referencia y compensación de valores automáticamente</h4>
                    </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Medida electroneumática para control de paralelismo y perpendicularidad</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto5} style={{marginLeft: '20px'}} alt="" class="iconesProd"/>
                    </div>
                    <div>
                    <h4 class="ccIcon">Micro Módulos-Procesados ​​con 02, 03 o 04 Canales de Medición - hasta 04 Dispositivos Simultáneos</h4>
                        <h4 class="cc2">(Customer: EMBRACO CHINA / MÉXICO)</h4>
                    </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Gabinete Especial y Múltiples medidas - Sistema Microprocesado</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto6} alt="" class="iconesProd"/>
                    </div>
                <div>
                    <h4 class="ccIcon">Estaciones de Medida para Control de Componentes de Compresores</h4>
                    <h4 class="cc2">(Customer: EMBRACO BRASIL, CHINA, ESLOVAQUIA)</h4>
                </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Solución completa para el control de componentes de compresores - Líneas de Montaje y Mecanizado</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto7} alt="" class="iconesProd"/>
                    </div>
                <div>
                    <h4 class="cc2">(Cliente: EMBRACO BRASIL, CHINA, ESLOVAQUIA)</h4>
                </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Banco de control de espacio libre - Disposición</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto8} alt="" class="iconesProd"/>
                    </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Banco de medición - Control de características como imágenes</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto9} alt="" class="iconesProd"/>
                    </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Equipos especiales y múltiples sistemas de medición</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto10} alt="" class="iconesProd"/>
                    </div>
                <div>
                    <h4 class="ccIcon">Bancos de medición para productos CRIN I y CRIN II</h4>
                    <h4 class="cc2">(Cliente: BOSCH)</h4>
                </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                <h4 class="ccIcon">Horquillas de medición y módulos electroneumáticos para medición de cigüeñales en máquina automática</h4>
            </div>
            <div class="iconeDiv2">
                        <img src={produto11} alt="" class="iconesProd"/>
                    </div>
                <div>
                    <h4 class="cc2">(Cliente: FRANCIA (MECALIX / EURALTHEC / CLEMESSY...))</h4>
                </div>
        </div>
        <br></br>
        <div class="dadosProd2" style={{borderTopLeftRadius: '40px', borderTopRightRadius: '40px'}}>
            <div>
                <h4 class="ccIcon" style={{marginTop: '5px', marginBottom: '2px'}}>NUESTRAS MÁQUINAS</h4>
            </div>
        </div>
        <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Fresadora Ferramenteira</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina1} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Fresadora Ferramenteira</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina2} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Afiadora Ferramenteira</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina3} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Metroscopio Universal Horizontal - Carl Zeiss Jena - ULM 600 / Serie 0135</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina4} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Torno Ferramenteiro</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina5} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Torno Mecánico</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina6} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Torno Revólver de fricción</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina7} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Rectificadora Studer de precisión</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina8} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Rectificadora Cilíndrica</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina9} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Rectificadora Plana</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina10} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Lijadora</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina11} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Sierra de cinta</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina12} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Coordenada Tridimensional</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina13} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Accesorios para fresa y medición</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina14} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Máquina para chorreado</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina15} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Placas Divisorias</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina16} alt="" class="iconesProd"/>
                </div>
            </div>
        </div>
        </>
    )
}