import React from 'react'
import "../../styles.css"
import pin from "../../images/pin.png"
import tel from "../../images/tel.png"
import email from "../../images/email.png"
import person from "../../images/person.png"
import logoColorida from "../../images/logo/logocoloridaEN.png"

export default function Contact() {
    return (
        <>
        <div class="imgBack" style={{marginBottom: '-100px', width: "100%", height: "100%"}}>
            <div class="atrasTexto">
                <h2 class="tituloContato">TECMETROL - MEASURING EQUIPMENTS LTD.</h2>
            </div>
            <div class="dadosContato" style={{paddingBottom: '30px'}}>
            <div class="textoFundo">
                <div class="iconeDiv">
                    <img src={pin} alt="" class="iconesContato"/><h4 class="ccIcon">Adress</h4>
                </div>
                <div>
                    <h4 class="cc2">Rua Siqueira Campos n° 34 - Vila Marchi</h4>
                    <h4 class="cc2">São Bernardo do Campo</h4>
                    <h4 class="cc2">São Paulo – Brazil</h4>
                    <h4 class="cc2">ZIP Code: 09810-460</h4>
                </div>
            </div>
                <br></br>
            <div class="textoFundo ">
                <div class="iconeDiv">
                    <img src={email} alt="" class="iconesContato"/><h4 class="ccIcon">E-mail</h4>
                </div>
                <div>
                    <h4 class="cc2">tecmetrol.tec@uol.com.br</h4>
                </div>
            </div>
                <br></br>
                <div class="textoFundo ">
                        <div class="iconeDiv">
                            <img src={person} alt="" class="iconesContato"/><h4 class="ccIcon">Eng. Salvador Carrillo Lopez</h4>
                        </div>
                        <br></br>
                            <p style={{color: 'white', marginTop: '-50px', fontSize: '15px', fontWeight: '500'}}>CEO</p>
                        <div>
                            <h4 class="cc2">52 (444) 193 63 96</h4>
                            <h4 class="cc2">scarrillo@tecmetrol.com.mx</h4>
                            <h4 class="cc2">www.tecmetrol.com.mx</h4>
                            <br></br>
                            <h4 class="cc2">Ctra. San Luis Potosi-Guadalajara 1510<br></br>
                                            Tomas del Tecnológico<br></br>
                                            San Luis Potosi, S.L.P. 78215 - México</h4>
                        </div>
                    </div>
                    <br></br>
                    <div class="textoFundo ">
                        <div class="iconeDiv">
                            <img src={person} alt="" class="iconesContato"/><h4 class="ccIcon">Eng. César Tovar</h4>
                        </div>
                        <br></br>
                                <p style={{color: 'white', marginTop: '-50px', fontSize: '15px', fontWeight: '500'}}>Director</p>
                        <div>
                            <h4 class="cc2">52 (444) 658 98 09</h4>
                            <h4 class="cc2">ctovar@tecmetrol.com.mx</h4>
                            <h4 class="cc2">www.tecmetrol.com.mx</h4>
                            <br></br>
                            <h4 class="cc2">Ctra. San Luis Potosi-Guadalajara 1510<br></br>
                                            Tomas del Tecnológico<br></br>
                                            San Luis Potosi, S.L.P. 78215 - México</h4>
                        </div>
                    </div>
                    <br></br>
            <div class="textoFundo">
                <div class="iconeDiv">
                    <img src={tel} alt="" class="iconesContato"/><h4 class="ccIcon">Phone</h4>
                </div>
                <div>
                    <h4 class="cc2">+55 (11) 3423-3181</h4>
                    <h4 class="cc2">+55 (11) 3423-5599</h4>
                </div>
                </div>
                </div>
                <img src={logoColorida} class="imgColorida" alt=""/>
            </div>
        </>
    )
}