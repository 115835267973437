import React from 'react'
import "../../styles.css"

import produto1 from "../../images/produtos/produto1.png"
import produto2 from "../../images/produtos/produto2.png"
import produto3 from "../../images/produtos/produto3.png"
import produto4 from "../../images/produtos/produto4.png"
import produto5 from "../../images/produtos/produto5EN.png"
import produto6 from "../../images/produtos/produto6.png"
import produto7 from "../../images/produtos/produto7.png"
import produto8 from "../../images/produtos/produto8.png"
import produto9 from "../../images/produtos/produto9.png"
import produto10 from "../../images/produtos/produto10.png"
import produto11 from "../../images/produtos/produto11.png"
import maquina1 from "../../images/produtos/maquina1.png"
import maquina2 from "../../images/produtos/maquina2.png"
import maquina3 from "../../images/produtos/maquina3.png"
import maquina4 from "../../images/produtos/maquina4.png"
import maquina5 from "../../images/produtos/maquina5.png"
import maquina6 from "../../images/produtos/maquina6.png"
import maquina7 from "../../images/produtos/maquina7.png"
import maquina8 from "../../images/produtos/maquina8.png"
import maquina9 from "../../images/produtos/maquina9.png"
import maquina10 from "../../images/produtos/maquina10.png"
import maquina11 from "../../images/produtos/maquina11.png"
import maquina12 from "../../images/produtos/maquina12.png"
import maquina13 from "../../images/produtos/maquina13.png"
import maquina14 from "../../images/produtos/maquina14.png"
import maquina15 from "../../images/produtos/maquina15.png"
import maquina16 from "../../images/produtos/maquina16.png"


export default function Products() {
   

    return (
        <>
        <div class="imgBack" style={{marginBottom: '-100px'}}>
            <div class="dadosProd2">
                <div>
                    <h4 class="ccIcon" style={{marginTop: '5px', marginBottom: '2px'}}>OUR PRODUCTS</h4>
                </div>
            </div>
            <div class="dadosProd">
                <div className="fundoTitulo">
                    <h4 class="ccIcon">Measuring devices and calibration standards</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={produto1} alt="" class="iconesProd2"/>
                        </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                        <h4 class="ccIcon" >Eletro-Pneumatic Columns with One Measuring Channel - One Device</h4>
            </div>
                <div class="iconeDiv2">
                    <img src={produto2} alt="" class="iconesProd"/>
                        </div>
                        <div>
                            <h4 class="cc2">Resolution: 0,0002 mm</h4>
                            <h4 class="cc2">Range: ± 0,006 up to ± 0,060 mm</h4>
                        </div>
            </div>
            <div class="dadosProd">
                <div className="fundoTitulo">
                    <h4 class="ccIcon">Micro-Processed Modules with Two, Three or Four Measuring Channels - up to Four Simultaneous Devices</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={produto3} alt="" class="iconesProd"/>
                        </div>
                        <div style={{marginTop: "-30px"}}>
                            <h4 class="cc2">Resolution: 0,0001 mm</h4>
                            <h4 class="cc2">Range: ± 0,002 up to ± 0,150 mm</h4>
                        </div>
            </div>
            <div class="dadosProd">
                <div className="fundoTitulo">
                    <h4 class="ccIcon">Micro-Processed Tecmetrol Modules - Last generation</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={produto4} alt="" class="iconesProd2"/>
                        </div>
                        <div>
                        <h4 class="ccIcon">MODELS</h4>
                        <h4 class="cc2">MICRO – PC W10 with 2 channels</h4>
                        <h4 class="cc2">MICRO – PC W10 with 3 channels</h4>
                        <h4 class="cc2">MICRO – PC W10 with 4 channels</h4>
                        <br></br>
                        <div>
                            <h4 class="cc2">Resolution: 0,0001 mm</h4>
                            <h4 class="cc2">Range: ± 0,002 up to ± 0,150 mm</h4>
                        </div>
                        <br></br>
                            <h4 class="cc2" style={{width: "70%"}}>The equipment works using Windows Platform, running an application software in LabView. There are options with two, three, four and up to eight measuring channels</h4>
                            <br></br>
                            <h4 class="cc2" style={{width: "70%"}}>This equipment operates with AUTO-ZERO System, which is a software routine that performs reference standard and compensation of values settings automatically</h4>
                        </div>
            </div>
            <div class="dadosProd">
                <div className="fundoTitulo">
                    <h4 class="ccIcon">Electro-Pneumatic Measuring to Paralelism and Perpendicularity control</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={produto5} style={{marginLeft: '20px'}} alt="" class="iconesProd"/>
                        </div>
                        <div>
                        <h4 class="ccIcon">Micro Modules-Processed with 02, 03 or 04 Measurement Channels - up to 04 Simultaneous Devices</h4>
                            <h4 class="cc2">(Customer: EMBRACO CHINA / MEXICO)</h4>
                        </div>
            </div>
            <div class="dadosProd">
                <div className="fundoTitulo">
                    <h4 class="ccIcon">Special Cabinet and Multiple measurements - Micro-Processed System</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={produto6} alt="" class="iconesProd"/>
                        </div>
                    <div>
                        <h4 class="ccIcon">Measuring Stations for Compressor Components Control</h4>
                        <h4 class="cc2">(Customer: EMBRACO BRAZIL, CHINA, SLOVAKIA)</h4>
                    </div>
            </div>
            <div class="dadosProd">
                <div className="fundoTitulo">
                    <h4 class="ccIcon">Complete solution to control compressor components - Assembly and Machining lines</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={produto7} alt="" class="iconesProd"/>
                        </div>
                    <div>
                        <h4 class="cc2">(Customer: EMBRACO BRASIL, CHINA, SLOVAKIA)</h4>
                    </div>
            </div>
            <div class="dadosProd">
                <div className="fundoTitulo">
                    <h4 class="ccIcon">Clearance Control Bench - Lay Out</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={produto8} alt="" class="iconesProd"/>
                        </div>
            </div>
            <div class="dadosProd">
                <div className="fundoTitulo">
                    <h4 class="ccIcon">Measuring Bench - Characteristics control as pictures</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={produto9} alt="" class="iconesProd"/>
                        </div>
            </div>
            <div class="dadosProd">
                <div className="fundoTitulo">
                    <h4 class="ccIcon">Special equipment and multiple measuring systems</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={produto10} alt="" class="iconesProd"/>
                        </div>
                    <div>
                        <h4 class="ccIcon">Measuring Benches for CRIN I and CRIN II  products</h4>
                        <h4 class="cc2">(Customer: BOSCH)</h4>
                    </div>
            </div>
            <div class="dadosProd">
                <div className="fundoTitulo">
                    <h4 class="ccIcon">Measuring forks and Electro-Pneumatic modules for Crankshaft measuring on automatic machine</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={produto11} alt="" class="iconesProd"/>
                        </div>
                    <div>
                        <h4 class="cc2">(Customer: FRANCE (MECALIX / EURALTHEC / CLEMESSY...))</h4>
                    </div>
            </div>
            <br></br>
            <div class="dadosProd2"  style={{borderTopLeftRadius: '40px', borderTopRightRadius: '40px'}}>
                <div>
                    <h4 class="ccIcon" style={{marginTop: '5px', marginBottom: '2px'}}>OUR MACHINES</h4>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Milling Machine</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina1} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Milling Machine</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina2} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Tool and Cutter Grinder</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina3} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Universal Horizontal Metroscope - Carl Zeiss Jena - ULM 600 / Series 0135</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina4} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Lathe Machine</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina5} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Mechanical Lathe</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina6} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Friction Revolver Lathe</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina7} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Studer Precision Grinder</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina8} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Cylindrical Grinder</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina9} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Surface Grinder</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina10} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Sander</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina11} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Band Saw</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina12} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Three-Dimensional Coordinate Measuring Machine</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina13} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Milling and Measuring Accessories</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina14} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Sandblasting Machine</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina15} alt="" class="iconesProd"/>
                </div>
            </div>
            <div class="dadosProd">
            <div className="fundoTitulo">
                    <h4 class="ccIcon">Dividing Plates</h4>
                </div>
                <div class="iconeDiv2">
                            <img src={maquina16} alt="" class="iconesProd"/>
                </div>
            </div>
        </div>
        </>
    )
}