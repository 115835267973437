import React from "react";
import { Container, Row } from "react-bootstrap";
import '../../styles.css'

function About() {
  return (
    <>
    <div class="imgBack" style={{marginBottom: '-100px'}}>
    <div class="dadosProd2">
            <div>
                <h4 class="ccIcon" style={{marginTop: '5px', marginBottom: '2px'}}>WHO WE ARE</h4>
            </div>
        </div>
    <Container className="my-5">
      <Row>
        <div class="fundoSobre" style={{marginTop: '-30px'}}>
          <h2 class="titulo">The Tecmetrol</h2>
          <ul>
            <li>
            Operating in the market since 1986, Tecmetrol was founded to Attend the needs 
            of Customers in the Brazilian Market who need Solutions for Measurement and 
            Control, through Control Devices and Special Measurement Systems.
            </li>
            <li>
            Since our foundation, we seek to know the demands of our Clients, through the 
            Knowledge of their Processes, in order to have the Ability to Present the Best 
            Solutions, treating each Client and Project in a Customized Way.
            </li>
            <li>
            Since 2002 we have also been operating in the Foreign Market, providing Special 
            Solutions to Large Customers in Europe, Asia and North America, meeting their 
            stringent Quality Requirements.
            </li>
            <li>
            We are attentive to Customer and Market Demands. In this sense, we seek to constantly
             update our Products and Solutions, such as the Compatibility of our Solutions with 
             Projects aimed at Industry 4.0. We present the Information with Quality and Reliability, so that it can be used in Decision Making by our Clients.
            </li>
            <li>
            This is Tecmetrol, a Company ready to present the best solutions for control and measurement systems, 
            building loyalty in commercial relationships through the constant search for the best cost x benefit 
            to our customers.
            </li>
          </ul>
          </div>
      </Row>
      <br></br>
      <Row>
      <div class="fundoSobre">
          <h2>Tecmetrol Team</h2>
          <h3>Complete internal structure:</h3>
          <ul>
            <li>Mechanical, Electrical, Electronics and Software Projects</li>
            <li>Complete Manufacturing Processes</li>
            <li>
            Measurement and Calibration (Certified by RBC – Brazilian Calibration Network)
            </li>
            <li>Electrical and Electronic Assembly</li>
            <li>Software Development and Implementation</li>
            <li>Technical assistance</li>
          </ul>
          <h3>Outsourced Processes (Certified Suppliers):</h3>
          <ul>
            <li>Superficial treatment</li>
            <li>Heat treatment</li>
          </ul>
          </div>
          </Row>
          <br></br>
          <Row>
          <div class="fundoSobre">
          <h2>Our products</h2>
          <ul>
            <li>Measuring and Control Devices in General</li>
            <li>Pneumatic and Electro-Pneumatic Devices</li>
            <li>Mechanical Devices</li>
            <li>
            Calibration Standards (Certified by RBC - Brazilian Calibration Network)
            </li>
            <li>Measurement benches</li>
            <li>Hardware and Software</li>
          </ul>
          </div>
          </Row>
          <br></br>
          <Row>
          <div class="fundoSobre">
            <h2>Customers</h2>
            <div class="clientes">
            <ul>
              <h4 class="listaTitle">BRAZIL</h4>
              <li class="li2">Robert Bosch Ltda.</li>
              <li class="li2">Renault do Brasil S/A</li>
              <li class="li2">Nidec Global Appliance Brasil Ltda.</li>
              <li class="li2">Weg Motores</li>
              <li class="li2">Weg Linhares Eq. El. S/A</li>
              <li class="li2">Volkswagen do Brasil S/A</li>
              <li class="li2">ZF Sistemas de Direção Ltda.</li>
              <li class="li2">DHB Comp. Automotivos Ltda.</li>
              <li class="li2">Fundição TUPY Ltda.</li>
              <li class="li2">Stellantis</li>
              <li class="li2">Bosch Rexroth Ltda.</li>
            </ul>
            <ul>
            <h4 class="listaTitle">WORLD</h4>
              <li class="li2">Bosch Automotive Diesel Systems Co. - CHINA</li>
              <li class="li2">Robert Bosch GMBH - Stuttgart - GERMANY</li>
              <li class="li2">Satherm GMBH - GERMANY</li>
              <li class="li2">
              Motor – Industries Co. - INDIA
              </li>
              <li class="li2">Nidec Compressor Co. Ltd. - SLOVAKIA</li>
              <li class="li2">Nidec Global Appliance Co. Ltd. - MEXICO</li>
              <li class="li2">Nidec Compressor Co. Ltd. - CHINA</li>
              <li class="li2">Euraltech Controles - FRANCE</li>
              <li class="li2">Mecalix Controle e Messure - FRANCE</li>
              <li class="li2">Axorys Systems Les Ulis - FRANCE</li>
              <li class="li2">Clemessy S/A</li>
              <li class="li2">RC Meca Realisations Contr. Mec. - FRANCE</li>
              <li class="li2">Promaktiv - RUSSIA</li>
            </ul>
            </div>
          </div>
      </Row>
    </Container>
    </div>
    </>
  );
}

export default About;