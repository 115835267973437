import React from 'react';
import { Carousel } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import img1 from '../../images/C1.png'
import img2 from '../../images/C2.png'
import img3 from "../../images/C3.png";
import mapa from "../../images/mapa.png";
import logotec2 from "../../images/logo/logobodyES.png";
import '../CustomCarousel/CustomCarousel.css';
import '../../styles.css'

export default function CustomCarouselES() {
    const isSmallScreen = useMediaQuery({ maxWidth: 768 });

    return (
      <>
        {isSmallScreen ? (
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={img3} alt="Third slide" />
              <Carousel.Caption id='caption'>
              <div>
                <img className="logotec3" src={logotec2} alt=""/>
                <p>Una Empresa dispuesta a presentar las mejores soluciones en sistemas de control y medición, fidelizando las relaciones comerciales a través de la búsqueda constante del mejor COSTO x BENEFICIO para nuestros clientes.
                </p>
                <img class="mapa" src={mapa} alt=""/>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img1} alt="First slide" />
              <Carousel.Caption id='caption'>
                <h3 class="c">Operando en el mercado desde 1986</h3>
                <p class="c2" style={{marginTop: "-50px"}}>
                Tecmetrol fue fundada para Atender las necesidades de los Clientes del Mercado Brasileño que necesitan Soluciones de Medida y Control, a través de Dispositivos de Control y Sistemas Especiales de Medida.
                </p>
                <br></br>
                <p style={{marginTop: "-20px"}}class="c2">Equipos de alta precisión y sistemas de medición automatizados, Hardwares y Periféricos, Dispositivos de Medición y Softwares de Medición.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img2} alt="Second slide" />
              <Carousel.Caption id='caption'>
                <h3 class="c">Desde 2002 <br/>también operamos en el Mercado Exterior</h3>
                <p class="c2" style={{marginTop: '70px'}}>Brindando soluciones especiales a grandes <br/> clientes en Europa, Asia y América del Norte, <br/> cumpliendo con sus estrictos requisitos de calidad.</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        ) : (
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={img3} alt="Third slide" />
              <Carousel.Caption id='caption'>
                <img className="logotec2" src={logotec2} alt=""/>
                <p>Una Empresa dispuesta a presentar las mejores soluciones en sistemas de control y medición, fidelizando las relaciones comerciales a través de la búsqueda constante del mejor COSTO x BENEFICIO para nuestros clientes.
                </p>
                <img class="mapa2" src={mapa} alt=""/>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img1} alt="First slide" />
              <Carousel.Caption id='caption'>
                <h3 class="c">Operando en el mercado desde 1986</h3>
                <p class="c2" style={{marginTop: '40px'}}>
                Tecmetrol fue fundada para Atender las necesidades de los Clientes del Mercado Brasileño que necesitan Soluciones de Medida y Control, a través de Dispositivos de Control y Sistemas Especiales de Medida.
                </p>
                <br></br>
                <p class="c2">Equipos de alta precisión y sistemas de medición automatizados, Hardwares y Periféricos, Dispositivos de Medición y Softwares de Medición.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img2} alt="Second slide" />
              <Carousel.Caption id='caption'>
              <h3 class="c">Desde 2002 <br/>también operamos en el Mercado Exterior</h3>
                <p class="c2" style={{marginTop: '100px'}}>Brindando soluciones especiales a grandes <br/> clientes en Europa, Asia y América del Norte, <br/> cumpliendo con sus estrictos requisitos de calidad.</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        )}
      </>
    );
}
